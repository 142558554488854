export enum Locale {
  NORWEGIAN_NORWAY = 'no',
  SWEDISH_SWEDEN = 'se',
  ENGLISH_NORWAY = 'en-no',
  ENGLISH_SWEDEN = 'en-se'
}

export enum DefaultLocale {
  NORWAY = Locale.NORWEGIAN_NORWAY,
  SWEDEN = Locale.SWEDISH_SWEDEN,
  FALLBACK = Locale.NORWEGIAN_NORWAY
}

const currentLocales = [Locale.ENGLISH_NORWAY, Locale.ENGLISH_SWEDEN, Locale.SWEDISH_SWEDEN, Locale.NORWEGIAN_NORWAY]

export const pathNameHasLocale = (pathname: string): boolean => {
  return currentLocales.some((locale) => pathname.concat('/').startsWith(`/${locale}/`))
}

export const extractLocaleFromPathname = (pathname: string): string => {
  if (!pathNameHasLocale(pathname)) {
    return DefaultLocale.FALLBACK
  }
  const matchedLocale = currentLocales.find((locale) => pathname.startsWith(`/${locale}/`)) || DefaultLocale.FALLBACK
  return matchedLocale
}
